import React, { useRef, useEffect } from 'react';

const VideoBackground = ({ isMuted, src, onEnded }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.muted = isMuted;
        }
    }, [isMuted]);

    return (
        <video
            ref={videoRef}
            autoPlay
            loop={false}
            muted={isMuted}
            onEnded={onEnded}
            style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: -1,
            }}>
            <source src={src} type="video/mp4" />
        </video>
    );
};

export default VideoBackground;
