import React, { useState, useRef } from "react";
import { View, TouchableOpacity, Image, Dimensions } from "react-native-web";

const { width } = Dimensions.get("window");
const isSmallDevice = width < 600;
const isTablet = width < 1500 && width > 600;

const SocialMediaWidget = () => {
	const [showEmbed, setShowEmbed] = useState(false);
	const hoverTimeoutRef = useRef(null);

	const facebookPageUrl = "https://www.facebook.com/somersetcountryclub";

	const openModal = () => {
		if (hoverTimeoutRef.current) {
			clearTimeout(hoverTimeoutRef.current);
		}
		setShowEmbed(true);
	};

	const closeModalWithDelay = () => {
		hoverTimeoutRef.current = setTimeout(() => {
			setShowEmbed(false);
		}, 1000);
	};

	const handleIconMouseEnter = () => {
		// openModal();
	};

	const handleIconMouseLeave = () => {
		closeModalWithDelay();
	};

	const handleModalMouseEnter = () => {
		// if (hoverTimeoutRef.current) {
		// 	clearTimeout(hoverTimeoutRef.current);
		// }
	};

	const handleModalMouseLeave = () => {
		closeModalWithDelay();
	};

	return (
		<View style={styles.container}>
			<button onClick={() => window.open("https://www.facebook.com/somersetcountryclub/")} onMouseEnter={handleIconMouseEnter} onMouseLeave={handleIconMouseLeave} style={styles.buttonStyle}>
				<img src="../assets/facebook (4) copie.png" style={styles.icon} alt="Facebook" />
			</button>

			{showEmbed && (
				<div style={styles.modalStyle} onMouseEnter={handleModalMouseEnter} onMouseLeave={handleModalMouseLeave}>
					<div style={styles.embedContainer}>
						<iframe src={`https://www.facebook.com/plugins/page.php?href=${encodeURIComponent(facebookPageUrl)}&tabs=timeline&width=800&height=700&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true`} width="500" height="700" style={{ border: "none", overflow: "hidden" }} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
					</div>
				</div>
			)}
			<button onClick={() => window.open("https://www.youtube.com/@SomersetCountryClub")} onMouseEnter={handleIconMouseEnter} onMouseLeave={handleIconMouseLeave} style={styles.buttonStyle}>
				<Image source={require("../assets/youtube (2) copie.png")} style={styles.icon} />
			</button>
		</View>
	);
};

const styles = {
	container: {
		position: "absolute",
		bottom: isSmallDevice ? -10 : isTablet ? -10 : -10,
		right: isSmallDevice ? 0 : isTablet ? 50 : 0,
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		padding: 25,
		backgroundColor: "transparent",
	},
	buttonStyle: {
		border: "none",
		backgroundColor: "transparent",
		cursor: "pointer",
	},
	icon: {
		width: 30,
		height: 30,
	},
	modalStyle: {
		position: "fixed",
		top: "30%",
		left: "30%",
		width: "40%",
		height: "40%",
		backgroundColor: "#214332",
		borderRadius: 50,
		padding: "20px",
		boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
		zIndex: 1000,
	},
	embedContainer: {
		width: "100%",
		height: "50%",
	},
};

export default SocialMediaWidget;
