import { StyleSheet, Dimensions } from "react-native";

const { width } = Dimensions.get("window");
const isSmallDevice = width < 600;
const isTablet = width < 1500 && width > 600;

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const styles = StyleSheet.create({
	body: {
		flex: 1,
		backgroundColor: "#214332",
		width: "100%",
		zIndex: 1,	
	},
	background: {
		flex: 1,
		zIndex: 0,
		width:  isSmallDevice ? "100%" : isTablet ? "100%" : "100%",
	},
	section: {
		flex: 1,
		minHeight: "100vh",
		resizeMode: "cover"
	},
	titleText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 50 : isTablet ? 100 : 125,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.5)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginTop: isSmallDevice ? "2.5%" : "2.5%",
		zIndex: 1,
	},
	subTitleText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 40 : isTablet ? 60 : 70,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.25)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginTop: isSmallDevice ? "2.5%" : "0%",
	},
	headerText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 40 : isTablet ? 90 : 100,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.75)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginTop: isSmallDevice ? 12.5 : 25,
		lineHeight: isSmallDevice ? 15 : 30,
		marginBottom: isSmallDevice ? 25 : 50,
		zIndex: 1,
		marginTop: isSmallDevice ? "7.5%" : "5%",
	},

	navBar: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		marginTop: isSmallDevice ? -10 : -5,
		paddingLeft: isSmallDevice ? 25 : 80,
		position: "relative",
		zIndex: 0,
		zIndex: 1,
	},

	navBarText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: isSmallDevice ? 13 : isTablet ? 30 : 35,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .75)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		paddingHorizontal: isSmallDevice ? 5 : 25,
		paddingBottom: 25,
		zIndex: 1,
	},
	navBar1: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		marginTop: isSmallDevice ? 0 : -5,
		paddingLeft: isSmallDevice ? 25 : 80,
		position: "relative",
		zIndex: 0,
		zIndex: 1,
	},
	navBarText1: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: isSmallDevice ? 13 : isTablet ? 30 : 35,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .75)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		paddingHorizontal: isSmallDevice ? 5 : 25,
		paddingBottom: 25,
		zIndex: 1,
	},
	navBarTextSP: {
		paddingTop: 25,
	},
	navBarHeader: {
		height: 65,
		backgroundColor: "#15221d",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingLeft: isSmallDevice ? 5 : 25,
		paddingRight: isSmallDevice ? 5 : 25,
		position: "relative",
		zIndex: 1000,
		elevation: 10,
	},
	navBarHeaderText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: isSmallDevice ? 15 : 25,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		paddingRight: 20,
	},
	mainContainer: {
		width: isSmallDevice ? "90%" : 1492,
		height: isSmallDevice ? 150 : 514,
		borderRadius: isSmallDevice ? 35 : 99,
		backgroundColor: "rgba(33, 67, 50, 0)",
		margin: "auto",
		marginTop: isSmallDevice ? "5%" : "2.5%",
		alignItems: "center",
	},
	mainContainerText: {
		width: isSmallDevice ? "90%" : 1352,
		height: isSmallDevice ? 200 : 375,
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 13 : 50,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		padding: isSmallDevice ? 15 : 30,
		opacity: 0,
	},
	golfContainer: {
		width: isSmallDevice ? "80%" : 800,
		height: isSmallDevice ? "70%" : 750,
		backgroundColor: "#214332",
		paddingVertical: isSmallDevice ? 15 : 30,
		paddingHorizontal: isSmallDevice ? 5 : 30,
		borderRadius: isSmallDevice ? 25 : 50,
		margin: "auto",
		top: isSmallDevice ? -55 : isTablet ? null : null,
	},
	proShop: {
		width: isSmallDevice ? "80%" : 800,
		height: isSmallDevice ? "45%" : 500,
		backgroundColor: "#214332",
		paddingVertical: isSmallDevice ? 15 : 30,
		paddingHorizontal: isSmallDevice ? 5 : 30,
		borderRadius: isSmallDevice ? 25 : 50,
		margin: "auto",
		marginTop: 25,
	},
	proShopHeaderText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 40 : isTablet ? 65 : 80,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.75)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginTop: isSmallDevice ? 12.5 : 25,
		lineHeight: isSmallDevice ? 15 : 30,
		marginBottom: isSmallDevice ? 25 : 50,
		zIndex: 1,
		marginTop: isSmallDevice ? "5%" : "2.5%",
	},
	textContainer: {
		width: isSmallDevice ? 294 : 588,
		height: isSmallDevice ? 216 : 432,
	},
	proShopDescriptionText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 16 : isTablet ? 26 : 30,
		lineHeight: isSmallDevice ? 20 : isTablet ? 35 : 38,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: isSmallDevice ? 20 : 15,
		paddingHorizontal: isSmallDevice ? 25 : 25,
	},
	descriptionText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 10 : isTablet ? 16 : 20,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 25,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
	row: {
		flexDirection: "row",
		justifyContent: "space-around",
	},
	column: {
		flex: 1,
		textAlign: "left",
	},
	boldText: {
		fontWeight: "bold",
		fontFamily: "Orpheus Pro",
		fontSize: isSmallDevice ? 10 : 20,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 25,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
	button: {
		width: isSmallDevice ? 188 : 376,
		height: isSmallDevice ? 30 : 60,
		backgroundColor: "#f7f7e0",
		justifyContent: "center",
		alignItems: "center",
		marginTop: isSmallDevice ? 10 : 40,
		borderRadius: isSmallDevice ? 15 : 30,
		margin: "auto",
		zIndex: 100,
	},
	buttonText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "bold",
		fontSize: isSmallDevice ? 16 : 24,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "center",
		color: "#214332",
		margin: "auto",
	},
	showVideoButton: {
		position: "absolute",
		top: 10,
		right: 10,
		backgroundColor: "#214332",
		padding: 10,
		zIndex: 101,
		height: isSmallDevice ? 50 : 75,
		width: isSmallDevice ? 50 : 75,
		borderRadius: 100,
		flex: 1,
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		marginRight: isSmallDevice ? 2.5 : 10,
		marginTop: isSmallDevice ? 2.5 : 10,
	},
	showFlyoversButton: {
		position: "absolute",
		top: 50,
		right: 10,
		backgroundColor: "#214332",
		padding: 10,
		zIndex: 101,
		height: isSmallDevice ? 50 : 75,
		width: isSmallDevice ? 50 : 75,
		borderRadius: 100,
		flex: 1,
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		marginRight: isSmallDevice ? 2.5 : 10,
		marginTop: isSmallDevice ? 2.5 : 50,
	},
	showVideo: {
		color: "#f7f7e0",
		fontFamily: "Orpheus Pro",
		textAlign: "center",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: isSmallDevice ? 12 : 18,
	},

	holesContainer: {
		position: "absolute",
		bottom: 0,
		left: 0,
		right: 0,
		flexDirection: "row",
		justifyContent: "space-around", // or 'space-evenly' depending on your design
		backgroundColor: "transparent", // or any color you prefer
	},
	holeButton: {
		backgroundColor: "#214332",
		padding: 10,
		zIndex: 101,
		height: isSmallDevice ? 50 : 40,
		width: isSmallDevice ? 50 : 30,
		borderRadius: 100,
		flex: 1,
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		marginVertical: isSmallDevice ? 2.5 : 25,
		marginHorizontal: isSmallDevice ? 2.5 : 10,
	},
	holeButtonText: {
		color: "#f7f7e0",
		fontFamily: "Orpheus Pro",
		textAlign: "center",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: isSmallDevice ? 12 : 16,
	},
	TTsubTitleText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 40 : isTablet ? 60 : 60,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.25)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginTop: isSmallDevice ? "2.5%" : "0%",
		marginBottom: 10,
	},
	golfContainerMTP: {
		width: isSmallDevice ? "80%" : isTablet ? 1000 : 1250,
		height: isSmallDevice ? "70%" : isTablet ? 600 : 600,
		backgroundColor: "#214332",
		paddingVertical: isSmallDevice ? 15 : 30,
		paddingHorizontal: isSmallDevice ? 5 : 30,
		borderRadius: isSmallDevice ? 25 : 50,
		margin: "auto",
		top: isSmallDevice ? -55 : isTablet ? null : null,
	},
	MTPsubTitleText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 30 : isTablet ? 50 : 50,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.25)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginTop: isSmallDevice ? "2.5%" : "0%",
	},
	MTPdescriptionText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 10 : isTablet ? 18 : 20,
		lineHeight: isSmallDevice ? 15 : 26,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginTop: 5,
		marginBottom: 25,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
	proImage: {
		width: "100%", // Example width
		height: isSmallDevice ? "20%" : isTablet ? "40%" : "50%", // Example height
	},
});

export default styles;
