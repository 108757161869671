import React, { useState, useEffect } from "react";
import { View, TextInput, Button, Text, FlatList, TouchableOpacity, StyleSheet, Dimensions } from "react-native";
import { getFirestore, collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { addDays, format } from 'date-fns';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const { width } = Dimensions.get("window");
const isSmallDevice = width < 600;
const isTablet = width < 1500 && width > 600;

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const Admin = () => {
	const db = getFirestore();

	// Banner Ribbon Vairables
	const [messages, setMessages] = useState([]);
	const [newMessage, setNewMessage] = useState("");
	const [displayTime, setDisplayTime] = useState("");
	// Job Listing Variables
	const [jobListings, setJobListings] = useState([]);
	const [newJobTitle, setNewJobTitle] = useState("");
	const [newJobDescription, setNewJobDescription] = useState("");
	// Events Variables
	const [isClubDatePickerVisible, setClubDatePickerVisibility] = useState(true);
	const [clubEvents, setClubEvents] = useState([]);
	const [newClubEventTitle, setNewClubEventTitle] = useState("");
	const [newClubEventDescription, setNewClubEventDescription] = useState("");
	const [newClubEventDate, setNewClubEventDate] = useState(""); // Assuming you want to add a date field
	const [showClubDatePicker, setShowClubDatePicker] = useState(false);
	// Events Variables
	const [isDatePickerVisible, setDatePickerVisibility] = useState(true);
	const [events, setEvents] = useState([]);
	const [newEventTitle, setNewEventTitle] = useState("");
	const [newEventDescription, setNewEventDescription] = useState("");
	const [newEventDate, setNewEventDate] = useState(""); // Assuming you want to add a date field
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [isDaily, setIsDaily] = useState(false);
	const [isWeekly, setIsWeekly] = useState(false);
	const [isBiWeekly, setIsBiWeekly] = useState(false);
	const [isMonthly, setIsMonthly] = useState(false);

	useEffect(() => {
		fetchMessages();
		fetchJobListings();
		fetchEvents();
		fetchClubEvents();
	}, []);

	// Example function to format Firestore date in ET
	

	const formatFirestoreDateInET = (firestoreDate) => {
			let date;
	
			if (typeof firestoreDate === "string") {
					// Direct conversion and adding a day to account for timezone-related shifts
					date = new Date(firestoreDate);
					date = addDays(date, 1); // Add one day
			} else if (firestoreDate instanceof Date) {
					// Use the Date object and add a day
					date = addDays(firestoreDate, 1);
			} else if (firestoreDate && typeof firestoreDate.toDate === "function") {
					// Firestore timestamp conversion and adding a day
					date = addDays(firestoreDate.toDate(), 1);
			} else {
					console.error("Unsupported date type:", firestoreDate);
					return "Date not available";
			}
	
			// Formatting the adjusted date
			try {
					return format(date, "MMMM dd, yyyy");
			} catch (error) {
					console.error("Error formatting date:", error);
					return "Date formatting error";
			}
	};
	





	// #region - Messages
	const fetchMessages = async () => {
		const querySnapshot = await getDocs(collection(db, "messages"));
		const fetchedMessages = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
		setMessages(fetchedMessages);
	};

	const handleSubmitMessage = async () => {
		if (newMessage) {
			await addDoc(collection(db, "messages"), {
				message: newMessage,
				duration: displayTime,
			});
			setNewMessage("");
			fetchMessages();
		}
	};

	const handleDeleteMessage = async (id) => {
		const isConfirmed = window.confirm("Are you sure you want to delete this message?");
		if (isConfirmed) {
			await deleteDoc(doc(db, "messages", id));
			fetchMessages();
		}
	};

	//#endregion

	// #region - Job Listings
	const fetchJobListings = async () => {
		const db = getFirestore();
		const querySnapshot = await getDocs(collection(db, "jobListings"));
		const fetchedJobListings = querySnapshot.docs.map((doc) => {
			const data = doc.data();
			// Optionally format date if present
			let formattedDate = data.date ? format(new Date(data.date), "MMMM dd, yyyy") : "N/A";
			return {
				id: doc.id,
				title: data.title,
				description: data.description,
				// Include the date if you're storing and wanting to display it
				date: formattedDate,
			};
		});
		setJobListings(fetchedJobListings);
	};

	const handleAddJobListing = async () => {
		if (newJobTitle && newJobDescription) {
			await addDoc(collection(db, "jobListings"), {
				title: newJobTitle,
				description: newJobDescription,
			});
			setNewJobTitle("");
			setNewJobDescription("");
			fetchJobListings();
		}
	};

	const handleDeleteJobListing = async (id) => {
		const isConfirmed = window.confirm("Are you sure you want to delete this job listing?");
		if (isConfirmed) {
			await deleteDoc(doc(db, "jobListings", id));
			fetchJobListings();
		}
	};

	//#endregion

	// #region - Events
	const fetchEvents = async () => {
		const db = getFirestore();
		const eventsSnapshot = await getDocs(collection(db, "events"));

		const fetchedEvents = eventsSnapshot.docs.map((doc) => {
			const data = doc.data();
			let formattedDate = "N/A";
			// Check if data.date is a Firestore Timestamp
			if (data.date && data.date.toDate) {
				// Convert Firestore Timestamp to JavaScript Date object
				const date = data.date.toDate();
				formattedDate = format(date, "MMMM dd, yyyy");
			} else if (data.date) {
				// Handle cases where date is already a JavaScript Date object or a valid date string
				try {
					const date = new Date(data.date);
					formattedDate = format(date, "MMMM dd, yyyy");
				} catch (error) {
					console.error("Error formatting date:", error);
				}
			}
			return {
				id: doc.id,
				title: data.title,
				description: data.description,
				date: formattedDate,
			};
		});

		setEvents(fetchedEvents);
	};

	const handleAddEvent = async () => {
		if (newEventTitle && newEventDescription && newEventDate && !isNaN(new Date(newEventDate).getTime())) {
			const eventDate = new Date(newEventDate);

			// Set to noon ET to avoid DST issues
			// This approach assumes ET is either UTC-5 or UTC-4
			eventDate.setHours(12); // Noon in local time

			// Adjust for the ET timezone offset (considering both standard and daylight saving time)
			// You may need to adjust this based on the specific dates and DST changes
			const offset = eventDate.getTimezoneOffset() < 300 ? 4 : 5; // Adjust based on whether it's DST or not
			eventDate.setHours(eventDate.getHours() - offset);

			const formattedDate = format(eventDate, "yyyy-MM-dd");
			await addDoc(collection(db, "events"), {
				title: newEventTitle,
				description: newEventDescription,
				date: formattedDate,
				isDaily,
				isWeekly,
				isBiWeekly,
				isMonthly,
			});

			// Reset states after adding
			setNewEventTitle("");
			setNewEventDescription("");
			setNewEventDate("");
			setIsDaily(false);
			setIsWeekly(false);
			setIsBiWeekly(false);
			setIsMonthly(false);
			fetchEvents();
		} else {
			console.log("Invalid date");
			// Handle the invalid date case appropriately
		}
	};

	const handleDeleteEvent = async (id) => {
		const isConfirmed = window.confirm("Are you sure you want to delete this event?");
		if (isConfirmed) {
			await deleteDoc(doc(db, "events", id));
			fetchEvents();
		}
	};

	//#endregion

	// #region - Club Events
	const fetchClubEvents = async () => {
		const db = getFirestore();
		const clubEventsSnapshot = await getDocs(collection(db, "club_events"));

		const fetchedClubEvents = clubEventsSnapshot.docs.map((doc) => {
			const data = doc.data();
			let formattedDate = "N/A";
			// Check if data.date is a Firestore Timestamp
			if (data.date && data.date.toDate) {
				// Convert Firestore Timestamp to JavaScript Date object
				const date = data.date.toDate();
				formattedDate = format(date, "MMMM dd, yyyy");
			} else if (data.date) {
				// Handle cases where date is already a JavaScript Date object or a valid date string
				try {
					const date = new Date(data.date);
					formattedDate = format(date, "MMMM dd, yyyy");
				} catch (error) {
					console.error("Error formatting date:", error);
				}
			}
			return {
				id: doc.id,
				title: data.title,
				description: data.description,
				date: formattedDate,
			};
		});

		setClubEvents(fetchedClubEvents);
	};

	const handleAddClubEvent = async () => {
		if (newClubEventTitle && newClubEventDescription && newClubEventDate && !isNaN(new Date(newClubEventDate).getTime())) {
			const formattedDate = format(new Date(newClubEventDate), "yyyy-MM-dd");
			await addDoc(collection(db, "club_events"), {
				title: newClubEventTitle,
				description: newClubEventDescription,
				date: formattedDate,
			});
			// Reset states after adding
			setNewClubEventTitle("");
			setNewClubEventDescription("");
			setNewClubEventDate("");
			fetchClubEvents();
		} else {
			console.log("Invalid date");
			// Handle the invalid date case appropriately
		}
	};

	const handleDeleteClubEvent = async (id) => {
		const isConfirmed = window.confirm("Are you sure you want to delete this event?");
		if (isConfirmed) {
			await deleteDoc(doc(db, "club_events", id));
			fetchClubEvents();
		}
	};

	//#endregion

	return (
		<View style={styles.body}>
			<Text style={styles.containerHeaderText}>Somerset Country Club</Text>
			<Text style={styles.containerDescriptionText}>Admin Page</Text>
			<View style={styles.container}>
				<View style={styles.section}>
					<View style={styles.section}>
						<Text style={styles.headerText}>Add New Ribbon Messages</Text>
						<TextInput value={newMessage} onChangeText={setNewMessage} placeholder="Enter your message" style={styles.input} />
						<TextInput value={String(displayTime)} onChangeText={(text) => setDisplayTime(Number(text))} placeholder="Display Time (ms)" keyboardType="numeric" style={styles.input} />
						<TouchableOpacity onPress={handleSubmitMessage} style={styles.addButton}>
							<Text style={styles.buttonText}>Add Message</Text>
						</TouchableOpacity>
					</View>

					<View style={styles.section}>
						<Text style={styles.headerText}>Existing Ribbon Messages</Text>
						<FlatList
							data={messages}
							keyExtractor={(item) => item.id.toString()}
							renderItem={({ item }) => (
								<View style={styles.listContainer}>
									<Text style={styles.containerHeaderText}>{item.message}</Text>
									<Text style={styles.containerDescriptionText}>Duration: {item.duration}ms</Text>
									<TouchableOpacity style={styles.deleteButton} title="Delete" onPress={() => handleDeleteMessage(item.id)}>
										<Text style={styles.buttonText}>Delete Message</Text>
									</TouchableOpacity>
								</View>
							)}
						/>
					</View>
				</View>

				<View style={styles.section}>
					<View style={styles.section}>
						<Text style={styles.headerText}>Add New Job Listings</Text>
						<TextInput value={newJobTitle} onChangeText={setNewJobTitle} placeholder="Job Title" style={styles.input} />
						<TextInput value={newJobDescription} onChangeText={setNewJobDescription} placeholder="Job Description" multiline style={styles.textarea} />
						<TouchableOpacity onPress={handleAddJobListing} style={styles.addButton}>
							<Text style={styles.buttonText}>Add Job Listing</Text>
						</TouchableOpacity>
					</View>

					<View style={styles.section}>
						<Text style={styles.headerText}>Existing Job Listings</Text>
						<FlatList
							data={jobListings}
							keyExtractor={(item) => item.id.toString()}
							renderItem={({ item }) => (
								<View style={styles.listContainer}>
									<Text style={styles.containerHeaderText}>{item.title}</Text>
									<Text style={styles.containerDescriptionText}>{item.description}</Text>
									<TouchableOpacity style={styles.deleteButton} title="Delete" onPress={() => handleDeleteJobListing(item.id)}>
										<Text style={styles.buttonText}>Delete Listing</Text>
									</TouchableOpacity>
								</View>
							)}
						/>
					</View>
				</View>

				<View style={styles.section}>
					<View style={styles.section}>
						<Text style={styles.headerText}>Add New Events</Text>
						<TextInput value={newEventTitle} onChangeText={setNewEventTitle} placeholder="Event Title" style={styles.input} />
						{/* Conditionally render the DateTimePicker */}
						<TouchableOpacity onPress={() => setShowDatePicker(true)} style={styles.input}>
							<Text style={styles.inputText}>{newEventDate ? format(new Date(newEventDate), "yyyy-MM-dd") : "Select Date"}</Text>
						</TouchableOpacity>
						{showDatePicker && (
							<ReactDatePicker
								selected={newEventDate ? new Date(newEventDate) : new Date()}
								style={styles.datePicker}
								onChange={(date) => {
									setNewEventDate(date);
									setShowDatePicker(false); // Optionally hide the picker after selection
								}}
								inline // Remove this if you prefer a popup
							/>
						)}

						<TextInput value={newEventDescription} onChangeText={setNewEventDescription} placeholder="Event Description" multiline style={styles.textarea} />

						<Text style={styles.text}>Recurrence Pattern:</Text>
						<View style={styles.checkboxContainer}>
							<View style={styles.checkboxRow}>
								<input type="checkbox" checked={isDaily} onChange={() => setIsDaily(!isDaily)} />
								<Text>Daily</Text>
							</View>
							<View style={styles.checkboxRow}>
								<input type="checkbox" checked={isWeekly} onChange={() => setIsWeekly(!isWeekly)} />
								<Text>Weekly</Text>
							</View>
							<View style={styles.checkboxRow}>
								<input type="checkbox" checked={isBiWeekly} onChange={() => setIsBiWeekly(!isBiWeekly)} />
								<Text>Bi-Weekly</Text>
							</View>
							<View style={styles.checkboxRow}>
								<input type="checkbox" checked={isMonthly} onChange={() => setIsMonthly(!isMonthly)} />
								<Text>Monthly</Text>
							</View>
						</View>

						<TouchableOpacity onPress={handleAddEvent} style={styles.addButton}>
							<Text style={styles.buttonText}>Add Event</Text>
						</TouchableOpacity>
					</View>

					<View style={styles.section}>
						<Text style={styles.headerText}>Existing Events</Text>
						<FlatList
							data={events.sort((a, b) => new Date(a.date) - new Date(b.date))}
							keyExtractor={(item) => item.id}
							renderItem={({ item }) => (
								<View style={styles.listContainer}>
									<Text style={styles.containerHeaderText}>{item.title}</Text>
									{/* Apply the formatting function here */}
									<Text style={styles.containerDescriptionText}>{item.date ? formatFirestoreDateInET(item.date) : "Date not available"}</Text>

									<Text style={styles.containerDescriptionText}>{item.description}</Text>
									<TouchableOpacity style={styles.deleteButton} title="Delete" onPress={() => handleDeleteEvent(item.id)}>
										<Text style={styles.buttonText}>Delete Event</Text>
									</TouchableOpacity>
								</View>
							)}
						/>
					</View>
				</View>

				<View style={styles.section}>
					<View style={styles.section}>
						<Text style={styles.headerText}>Add New Club Events</Text>
						<TextInput value={newClubEventTitle} onChangeText={setNewClubEventTitle} placeholder="Club Event Title" style={styles.input} />
						{/* Conditionally render the DateTimePicker */}
						<TouchableOpacity onPress={() => setShowClubDatePicker(true)} style={styles.input}>
							<Text style={styles.inputText}>{newClubEventDate ? format(new Date(newClubEventDate), "yyyy-MM-dd") : "Select Date"}</Text>
						</TouchableOpacity>
						{showClubDatePicker && (
							<ReactDatePicker
								selected={newClubEventDate ? new Date(newClubEventDate) : new Date()}
								style={styles.datePicker}
								onChange={(date) => {
									setNewClubEventDate(date);
									setShowClubDatePicker(false); // Optionally hide the picker after selection
								}}
								inline // Remove this if you prefer a popup
							/>
						)}

						<TextInput value={newClubEventDescription} onChangeText={setNewClubEventDescription} placeholder="Club Event Description" multiline style={styles.textarea} />

						<TouchableOpacity onPress={handleAddClubEvent} style={styles.addButton}>
							<Text style={styles.buttonText}>Add Club Event</Text>
						</TouchableOpacity>
					</View>

					<View style={styles.section}>
						<Text style={styles.headerText}>Existing Club Events</Text>
						<FlatList
							data={clubEvents.sort((a, b) => new Date(a.date) - new Date(b.date))}
							keyExtractor={(item) => item.id}
							renderItem={({ item }) => (
								<View style={styles.listContainer}>
									<Text style={styles.containerHeaderText}>{item.title}</Text>
									<Text style={styles.containerDescriptionText}>{item.date ? formatFirestoreDateInET(item.date) : "Date not available"}</Text>

									<Text style={styles.containerDescriptionText}>{item.description}</Text>
									<TouchableOpacity style={styles.deleteButton} title="Delete" onPress={() => handleDeleteClubEvent(item.id)}>
										<Text style={styles.buttonText}>Delete Club Event</Text>
									</TouchableOpacity>
								</View>
							)}
						/>
					</View>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	body: {
		backgroundColor: "#214332", // A lighter background to contrast with elements
		height: "100%",
		width: "100%",
		flex: 1,
		alignItems: "center",
		padding: 20, // Reduced padding for a more spacious layout
	},
	container: {
		flex: 1,
		padding: 20,
		backgroundColor: "#f7f7e0", // A clean white background for the content
		height: "auto", // Adjusted for content size
		width: "90%", // Slightly reduced for margin from screen edges
		borderRadius: 10,
		boxShadow: "0 4px 6px rgba(0,0,0,0.1)", // Soft shadow for depth
	},
	section: {
		marginBottom: 25,
		padding: 15, // Added padding for spacing inside sections
		backgroundColor: "#f7f7e0", // Ensuring section backgrounds are white
		borderRadius: 8,
		boxShadow: "0 2px 4px rgba(0,0,0,0.05)", // Softer shadow for the section
	},
	button: {
		borderRadius: 5, // Modern rounded corners
		backgroundColor: "#", // A vibrant button color
		color: "white", // Text color for buttons
		padding: 10, // Padding inside buttons for a larger touch area
		textAlign: "center", // Centering button text
		marginVertical: 10, // Margin vertical for spacing around buttons
		backgroundColor: "#214332", // Ensuring section backgrounds are white
	},
	headerText: {
		fontSize: 22, // Slightly larger font for headers
		marginBottom: 15, // Added margin below headers for spacing
		fontFamily: "Orpheus Pro", // Modern font-family (ensure it's supported or use a similar one)
		color: "#333333", // Darker color for better contrast and readability
	},
	input: {
		borderWidth: 1,
		borderColor: "#cccccc",
		borderRadius: 5,
		padding: 10,
		marginVertical: 5,
		backgroundColor: "#ffffff",
		fontFamily: "Helvetica Neue", // Make sure this font is available
		fontSize: 16, // Adjust fontSize for better readability
	},
	text: {
		borderColor: "#cccccc",
		borderRadius: 5,
		padding: 10,
		marginVertical: 5,
		fontFamily: "Helvetica Neue", // Make sure this font is available
		fontSize: 16, // Adjust fontSize for better readability
	},
	datePicker: {
		borderWidth: 1,
		borderColor: "#cccccc",
		borderRadius: 5,
		padding: 10,
		marginVertical: 5,
		backgroundColor: "#ffffff",
		fontFamily: "Helvetica Neue", // Make sure this font is available
		fontSize: 16, // Adjust fontSize for better readability
		width: "50%",
	},
	textarea: {
		minHeight: 100,
		textAlignVertical: "top",
		borderWidth: 1,
		borderColor: "#cccccc",
		borderRadius: 5,
		padding: 10,
		marginVertical: 5,
		backgroundColor: "#ffffff",
		fontFamily: "Helvetica Neue", // Make sure this font is available
		fontSize: 16, // Adjust fontSize for better readability
	},

	listContainer: {
		backgroundColor: "#214332", // Slight background color for list items for differentiation
		padding: 25, // Padding inside list items
		borderRadius: 25, // Rounded corners for list items
		boxShadow: "0 3px 2px rgba(0,0,0,0.15)", // Subtle shadow for list items
		marginVertical: 5, // Vertical margin for spacing between list items
		fontFamily: "Helvetica Neue", // Modern font-family (ensure it's supported or use a similar one)
		color: "#333333", // Darker color for better contrast and readability
	},
	listItem: {
		backgroundColor: "#f7f7e0", // Slight background color for list items for differentiation
		padding: 10, // Padding inside list items
		borderRadius: 5, // Rounded corners for list items
		boxShadow: "0 1px 2px rgba(0,0,0,0.05)", // Subtle shadow for list items
		marginVertical: 5, // Vertical margin for spacing between list items
		fontFamily: "Helvetica Neue", // Modern font-family (ensure it's supported or use a similar one)
		color: "#333333", // Darker color for better contrast and readability
	},
	listText: {
		color: "#f7f7e0", // A lighter background to contrast with elements
		alignItems: "center",
		fontSize: 15,
		padding: 2.5, // Reduced padding for a more spacious layout
	},
	datePicker: {
		position: "absolute",
		marginLeft: -100,
	},
	addButton: {
		backgroundColor: "#214332", // Adjust to your desired "Add" button color
		borderRadius: 5,
		padding: 10,
		alignItems: "center",
		marginTop: 10,
	},
	deleteButton: {
		backgroundColor: "red", // Red color for the "Delete" button
		borderRadius: 5,
		padding: 10,
		alignItems: "center",
		marginTop: 10,
	},
	buttonText: {
		color: "#ffffff", // White text for better readability
		fontSize: 16,
	},
	containerHeaderText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 15 : 37,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		margin: "auto",
	},
	containerDescriptionText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 11 : 25,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		margin: "auto",
		paddingBottom: 10,
	},
	checkboxContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		marginTop: 10,
	},
	checkboxRow: {
		flexDirection: "row",
		alignItems: "center",
		marginRight: 20,
	},
});

export default Admin;
