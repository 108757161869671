import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { View, Text, TouchableOpacity, StyleSheet, Dimensions } from "react-native";

const defaultCenter = {
	lat: 39.9989631,
	lng: -79.0693435,
};

const InteractiveMap = () => {
	const [windowDimensions, setWindowDimensions] = useState(Dimensions.get("window"));

	useEffect(() => {
		const subscription = Dimensions.addEventListener("change", ({ window }) => {
			setWindowDimensions(window);
		});

		return () => subscription?.remove();
	}, []);

	const { width } = Dimensions.get("window");

	const isSmallDevice = width < 600;
	const isTablet = width < 1500 && width > 600;
	
	
	const styles = generateStyles(isSmallDevice);

	const navigateToLocation = () => {
		console.log('Navigate');
		const url = "https://maps.google.com/maps/dir/?api=1&destination=416+Plank+Road+Somerset+PA+15501";
		window.open(url, "_blank");
	};

	return (
		<View style={styles.container}>
			<LoadScript googleMapsApiKey="AIzaSyDpSLfefT1RvHN8rNuDTHxeSSkK_4O3znc">
				<GoogleMap mapContainerStyle={styles.mapStyles} zoom={isSmallDevice ? 13 : isTablet ? 14 : 15} center={defaultCenter}>
					<Marker position={defaultCenter} />
				</GoogleMap>
			</LoadScript>
			<TouchableOpacity onPress={navigateToLocation} style={styles.button}>
				<Text style={styles.buttonText}>Navigate</Text>
			</TouchableOpacity>
		</View>
	);
};

const generateStyles = (isSmallDevice, isTablet) => StyleSheet.create({
	container: {
		flexDirection: "column",
		alignItems: "center",
		marginHorizontal: "auto",
		marginTop: isSmallDevice ? 10 : isTablet ? 25 : 25,
	},
	mapStyles: {
		width: isSmallDevice ? 350 : isTablet ? 1100 : 1100,
		height: isSmallDevice ? 350 : isTablet ? 500 : 550,
		borderRadius: isSmallDevice ? 50 : isTablet ? 50 : 50,
		backgroundColor: "#214332",
		boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
		paddingVertical: 10,
		alignItems: "center",
		marginHorizontal: "auto",
	},
	button: {
		width: isSmallDevice ? '80%' : isTablet ? 376 : 376,
		height: isSmallDevice ? 45 : isTablet ? 60 : 60,
		borderRadius: isSmallDevice ? 22 : isTablet ? 30 : 30,
		backgroundColor: "#f7f7e0",
		justifyContent: "center",
		alignItems: "center",
		boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
		marginTop: 20,
		cursor: "pointer",
	},
	buttonText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 24 : isTablet ? 25 : 33,
		textAlign: "center",
		color: "#224332",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
	},
});

export default InteractiveMap;
