import React, { useState, useEffect } from "react";
import { View, TextInput, Button, Text, StyleSheet } from "react-native";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

export const Login = ({ navigation }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // const [email, setEmail] = useState("club@somersetcountryclub.com");
    // const [password, setPassword] = useState("GolfSomerset!!");
    const [error, setError] = useState("");

    const auth = getAuth();
    const db = getFirestore();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                // User is signed in, check isAdmin from Firestore
                const docRef = doc(db, "users", user.uid);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    if (userData.isAdmin) {
                        // Navigate to Admin
                        navigation.navigate("Admin");
                    } else {
                      alert("You are not authorized to visit this page.")
                    }
                } else {
                    console.log("No such document!");
                }
            }
        });

        return unsubscribe; // Clean up subscription
    }, [navigation]);

    const handleLogin = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Navigation is handled by the onAuthStateChanged listener
            })
            .catch((error) => {
                console.log(error.message);
                setError("Error logging you in, please try again.")
            });
    };

    return (
        <View style={styles.container}>
            <Text style={styles.header}>Admin Login</Text>
            {error ? <Text style={styles.error}>{error}</Text> : null}
            <TextInput
                style={styles.input}
                placeholder="Email"
                value={email}
                onChangeText={setEmail}
            />
            <TextInput
                style={styles.input}
                placeholder="Password"
                value={password}
                onChangeText={setPassword}
                secureTextEntry
            />
            <Button title="Login" onPress={handleLogin} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        padding: 20,
    },
    header: {
        fontSize: 24,
        marginBottom: 20,
        textAlign: "center",
    },
    input: {
        marginBottom: 20,
        borderWidth: 1,
        padding: 10,
        borderRadius: 5,
    },
    error: {
        color: "red",
        marginBottom: 10,
    },
});


export default Login;