import { StyleSheet, Dimensions } from "react-native";

const { width } = Dimensions.get("window");

const isSmallDevice = width < 600;
const isTablet = width < 1500 && width > 600;

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const styles = StyleSheet.create({
	body: {
		flex: 1, // Use flex to make it cover the entire viewport
		backgroundColor: "#214332",
	},
	background: {
		flex: 1, // Use flex to make it cover the entire viewport
	},
	section: {
		flex: 1, // Each section takes up an equal portion of the screen's height
		minHeight: "100vh",
	},

	headerText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 40 : isTablet ? 90 : 100,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.25)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginTop: isSmallDevice ? "2.5%" : "1%",
	},
	contactForm: {
		height: "10%",
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		marginTop: isSmallDevice ? 25 : isTablet ? 50 : 50,
		marginBottom: 100,
	},
	membershipCardHeader: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 40 : 65,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.25)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginVertical: "2.5%", // Center the text vertically
	},
	navBar: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		marginTop: isSmallDevice ? 10 : 0,
		marginBottom: isSmallDevice ? 0 : 0,
		position: "relative",
		zIndex: 0,
		zIndex: 1,
	},
	navBarText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: isSmallDevice ? 13 : isTablet ? 30 : 35,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 1)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		paddingHorizontal: isSmallDevice ? 10 : 20,
		cursor: "pointer",
	},
	navBarHeader: {
		height: 65,
		backgroundColor: "#15221d",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingLeft: isSmallDevice ? 5 : 25,
		paddingRight: isSmallDevice ? 5 : 25,
		position: "relative",
		zIndex: 1000,
		elevation: 10,
	},
	navBarHeaderText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: isSmallDevice ? 15 : isTablet ? 18 : 0,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		paddingRight: 20,
	},
	navLogo: {
		width: 74,
		height: 45,
	},
	mainContainer: {
		width: isSmallDevice ? "50%" : 1492,
		height: isSmallDevice ? 150 : 514,
		borderRadius: isSmallDevice ? 35 : 99,
		backgroundColor: "#214332",
		margin: "auto",
		marginTop: isSmallDevice ? "5%" : "2.5%",
		alignItems: "center",
	},
	mainContainerText: {
		width: isSmallDevice ? "90%" : 1352,
		height: isSmallDevice ? 200 : 375,
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 15 : 62,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		padding: isSmallDevice ? 15 : 30,
	},
	membershipContainer: {
		width: isSmallDevice ? 350 : isTablet ? 764 : 764,
		height: isSmallDevice ? 475 : isTablet ? 650 : 650,
		backgroundColor: "#214332",
		filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))",
		paddingHorizontal: 30,
		paddingVertical: 20,
		borderRadius: 50,
		marginTop: isSmallDevice ? "5%" : "2.5%",
		marginHorizontal: "auto"
	},
	textContainer: {
		width: 588,
		height: 432,
	},
	innerContainer: {
		width: isSmallDevice ? "90%" : isTablet ? 986 : 986,
		height: isSmallDevice ? "auto" : isTablet ? 154 : 154,
		borderRadius: isSmallDevice ? 25 : isTablet ? 50 : 50,
		backgroundColor: "#214332",
		boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
		marginBottom: 20, // Adjust as needed
		padding: 20, // Adjust as needed
		margin: "auto", // Center the container horizontally
		marginTop: 20,
	},
	descriptionText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 16 : isTablet ? 20 : 20,
		lineHeight: isSmallDevice ? 15 : isTablet ? 30 : 30,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: isSmallDevice ? 16 : 25,
		textAlign: "center",
		paddingHorizontal: isSmallDevice ? 5 : 50,
	},
	boldText: {
		fontWeight: "bold",
		fontSize: isSmallDevice ? 24 : isTablet ? 20 : 20,
	},
	label: {
		marginBottom: 5,
		fontFamily: "Orpheus Pro",
		color: "#f7f7e0",
		fontSize: isSmallDevice ? 14 : 24,
		marginLeft: isSmallDevice ? 20 : 75,
	},
	input: {
		width: isSmallDevice ? "90%" : 630,
		height: isSmallDevice ? 24 : 47,
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 14 : 20,
		borderRadius: 23.5,
		backgroundColor: "#f7f7e0",
		color: "#214332",
		padding: 20,
		marginBottom: isSmallDevice ? 5 : 15,
		marginHorizontal: "auto",
	},
	submitButtonText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 14 : 30,
		textAlign: "center",
		color: "#214332",
		margin: "auto",
	},
	submitButton: {
		width: isSmallDevice ? "60%" : 400,
		height: isSmallDevice ? 40 : 60,
		marginTop: isSmallDevice ? 15 : isTablet ? 25 : 35,
		borderRadius: 30,
		backgroundColor: "#f7f7e0",
		boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
		marginHorizontal: "auto",
	},
	containerHeaderText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 20 : isTablet ? 32 : 32,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginHorizontal: "auto", // Center the container horizontally
	},
	containerDescriptionText: {
		// Your containerDescriptionText styles here
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 14 : isTablet ? 24 : 24,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginHorizontal: "auto", // Center the container horizontally
		paddingVertical: isSmallDevice ? 5 : 4,
		paddingHorizontal: 25,
	},
	jobListingContainer: {
		padding: 25
	}
});

export default styles;
