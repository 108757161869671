import React, { useState } from "react";
import { View, Pressable, TextInput, Button, Text, StyleSheet, Dimensions, Linking } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/FontAwesome"; // Import FontAwesome icons

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};


const generateStyles = (isSmallDevice, isTablet) =>
	StyleSheet.create({
		container: {
			width: isSmallDevice ? "90%" : isTablet ? "80%" : "80%",
			height: isSmallDevice ? "100%" : isTablet ? "110%" : "110%",
			padding: isSmallDevice ? 20 : isTablet ? 65 : 50,
			borderRadius: isSmallDevice ? 40 : isTablet ? 65 : 65,
			backgroundColor: "#214332",
			alignItems: "center",
			flexDirection: "row",
		},
		title: {
			fontSize: isSmallDevice ? 34 : isTablet ? 50 : 50,
			color: "#f7f7e0",
			marginBottom: isSmallDevice ? 15 : 20,
			fontFamily: fontFamilies.Bodoni72Smallcaps,
		},
		description: {
			width: isSmallDevice ? "20%" : isTablet ? 350 : 400,
			fontSize: isSmallDevice ? 18 : isTablet ? 20 : 24,
			color: "#f7f7e0",
			marginBottom: isSmallDevice ? 25 : 30,
			fontFamily: fontFamilies.Bodoni72Smallcaps,
			marginRight: isSmallDevice ? 10 : isTablet ? 100 : 90,
		},
		leftText: {
			fontSize: isSmallDevice ? 18 : 20,
			color: "#f7f7e0",
			lineHeight: 30,
			paddingHorizontal: isSmallDevice ? 13 : 30,
			bottom: isSmallDevice ? 8 : null,
			fontFamily: fontFamilies.Bodoni72Smallcaps,
		},
		iconTextContainer: {
			flex: 1,
			flexDirection: "row",
			paddingBottom: 5,
			textAlign: "left",
			justifyContent: "left"
		},
		label: {
			fontSize: isSmallDevice ? 13 : 22,
			color: "#f7f7e0",
			marginLeft: isSmallDevice ? 10 : 10,
			marginBottom: 10,
			fontFamily: fontFamilies.Bodoni72Smallcaps,
		},
		input: {
			width: isSmallDevice ? "20%" : isTablet ? "100%" : 630,
			height: isSmallDevice ? 20 : 40,
			backgroundColor: "#f7f7e0",
			color: "#214332",
			padding: 20,
			marginBottom: 15,
			fontFamily: fontFamilies.Bodoni72Smallcaps,
			fontSize: 22,
			borderRadius: 25
		},
		textarea: {
			width: isSmallDevice ? "20%" : isTablet ? "100%" : 630,
			height: isSmallDevice ? 200 : 250,
			backgroundColor: "#f7f7e0",
			color: "#214332",
			padding: 20,
			marginBottom: 15,
			fontSize: 22,
			fontFamily: fontFamilies.Bodoni72Smallcaps,
			borderRadius: 25
		},
		buttonText: {
			fontSize: isSmallDevice ? 18 : isTablet ? 24 : 24,
			color: "#214332",
			fontFamily: fontFamilies.Bodoni72Smallcaps,
		},
		button: {
			width: isSmallDevice ? "100%" : isTablet ? 350 : 500,
			height: isSmallDevice ? 0 : isTablet ? 50 : 50,
			backgroundColor: "#f7f7e0",
			borderRadius: 30,
			justifyContent: "center",
			alignItems: "center",
			marginLeft: isSmallDevice ? 25 : isTablet ? 30 : 65,
			marginTop: isSmallDevice ? 0: isTablet ? 0 : 10,
		},

		right: {
			flex: 1,
			justifyContent: "center",
			alignItems: "left",
			width: isSmallDevice ? "0%" : isTablet ? "50%" : "50%"
		}
	});

const ContactForm = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");

	const { width } = Dimensions.get("window");
	const isSmallDevice = width < 600;
	const isTablet = width < 1400 && width > 600;
	const styles = generateStyles(isSmallDevice, isTablet);


	const handleSubmit = () => {
		// You can include additional checks or validations here
		console.log("Form submitted:", { name, email, message });
		const emailRecipient = "club@somersetcountryclub.com";
		const emailSubject = encodeURIComponent(`Membership Inquiry - ${name}`);
		const emailBody = encodeURIComponent(`Name: ${name}\nEmail: ${email}\n\n Hi there!\n ${message} Thank you, \n ${name}`);
		// Open the mail app
		Linking.openURL(`mailto:${emailRecipient}?subject=${emailSubject}&body=${emailBody}`);
	};

	const handlePhonePress = () => {
		// Open the phone app with the provided phone number
		Linking.openURL("tel:(814)445-5200");
	};

	const handleEmailPress = () => {
		// Open the email app with the provided email address
		Linking.openURL("mailto:club@somersetcountryclub.com");
	};

	const handleAddressPress = () => {
		// Open the maps app with the provided address for directions
		Linking.openURL("https://maps.google.com/?q=416+Plank+Road+Somerset+PA+15501");
	};

	return (
		<View style={styles.container}>
			<View style={styles.left}>
				<Text style={styles.title}>Let's Talk</Text>
				<Text style={styles.description}>
					Ready to get started? Fill out the form to receive more information about our club, services, and availabilities. Our event coordinators will be in touch with you shortly to answer any questions you may have.
					{"\n\n"}
					Prefer to speak with someone directly? Give us a call, email us, or get directions to the club by clicking the links down below.
				</Text>
				{/* This is a space for another description if you'd like to add */}
				<View style={styles.additionalDescription}>
					{/* Phone */}
					<Pressable onPress={handlePhonePress}>
						<View style={styles.iconTextContainer}>
							<Icon name="phone" size={isSmallDevice ? 17.5 : 25} color="#f7f7e0" />
							<Text style={styles.leftText}>(814) 445-5200</Text>
						</View>
					</Pressable>

					{/* Email */}
					<Pressable onPress={handleEmailPress}>
						<View style={styles.iconTextContainer}>
							<Icon name="at" size={isSmallDevice ? 17.5 : 25} color="#f7f7e0" />
							<Text style={styles.leftText}>club@somersetcountryclub.com</Text>
						</View>
					</Pressable>

					{/* Address */}
					<Pressable onPress={handleAddressPress}>
						<View style={styles.iconTextContainer}>
							<Icon name="map-marker" size={isSmallDevice ? 17.5 : 25} color="#f7f7e0" />
							<Text style={styles.leftText}>416 Plank Road Somerset, PA 15501</Text>
						</View>
					</Pressable>
				</View>
			</View>

			<View style={styles.right}>
				<Text style={styles.label}>Name</Text>
				<TextInput value={name} onChangeText={setName} placeholder="Jane Doe" style={styles.input} />
				<Text style={styles.label}>Email</Text>
				<TextInput value={email} onChangeText={setEmail} placeholder="info@example.com" style={styles.input} />
				<Text style={styles.label}>Message</Text>
				<TextInput value={message} onChangeText={setMessage} placeholder="Drop us a line..." style={styles.textarea} multiline numberOfLines={4} />
				<TouchableOpacity title="Submit" onPress={handleSubmit} style={styles.button}>
					<Text style={styles.buttonText}>Submit</Text>
				</TouchableOpacity>
			</View>
		</View>
	);
};

export default ContactForm;
