import React, { useState, useRef } from "react";
import { View, Text, Image, StyleSheet, Dimensions, Pressable, Animated } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useNavigation } from "@react-navigation/native";

const { width } = Dimensions.get("window");
const isSmallDevice = width < 600;
const isTablet = width < 1500 && width > 600;

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const NavBarHeader = () => {
	const navigation = useNavigation();
	const [menuVisible, setMenuVisible] = useState(false);
	const menuAnimation = useRef(new Animated.Value(-width)).current; // Start off-screen to the left
	const menuOpacity = useRef(new Animated.Value(0)).current; // Initial opacity set to 0

	const toggleMenu = () => {
		console.log("Toggle menu pressed"); // This will log in your JavaScript console
		Animated.timing(menuAnimation, {
			toValue: menuVisible ? -width : 0, // Slide in from left to cover full screen
			duration: 300,
			useNativeDriver: false // Set to false to animate non-native properties like backgroundColor
		}).start();

		Animated.timing(menuOpacity, {
			toValue: menuVisible ? 0 : 1, // Correctly toggle opacity between 0 and 1
			duration: 300,
			useNativeDriver: false // Opacity needs to be animated by JavaScript
		}).start(() => {
			setMenuVisible(!menuVisible); // Toggle visibility state after animation
		});
	};

	return (
		<View style={styles.container}>
			<View style={styles.headerContent}>
				{isSmallDevice ? (
					<Pressable onPress={toggleMenu} style={styles.hamburger}>
						<Text style={styles.hamburgerIcon}>☰</Text>
					</Pressable>
				) : (
					<View style={styles.navItemsContainer}>
						<TouchableOpacity onPress={() => navigation.navigate("Home")}>
							<Text style={styles.navText}>Home</Text>
						</TouchableOpacity>
						<TouchableOpacity onPress={() => navigation.navigate("Golf")}>
							<Text style={styles.navText}>Golf</Text>
						</TouchableOpacity>
						<TouchableOpacity onPress={() => navigation.navigate("Events")}>
							<Text style={styles.navText}>Events</Text>
						</TouchableOpacity>
						<TouchableOpacity onPress={() => navigation.navigate("Contact")}>
							<Text style={styles.navText}>Contact</Text>
						</TouchableOpacity>
					</View>
				)}
			</View>

			<TouchableOpacity onPress={() => navigation.navigate("Home")} style={styles.logoContainer}>
				<Image
					source={require("../assets/19S21 Logo.png")}
					style={styles.logo}
					resizeMode="contain"
				/>
			</TouchableOpacity>

			{isSmallDevice && (
				<Animated.View
					style={[
						styles.menuOverlay,
						{
							transform: [{ translateX: menuAnimation }],
							opacity: menuOpacity, // Controlled by state
							width: width, // Ensure full width
							height: '100%', // Full screen height
							backgroundColor: "rgba(21, 35, 29, 0.85)" // Slightly transparent for visibility
						}
					]}
				>

<View style={styles.menuTopper}></View>
						<Pressable onPress={toggleMenu} style={styles.closeButton}>
							<Text style={styles.menuText}>✕</Text>
						</Pressable>
						<Pressable onPress={() => { navigation.navigate('Home'); toggleMenu(); }}>
							<Text style={styles.menuText}>Home</Text>
						</Pressable>
						<Pressable onPress={() => { navigation.navigate('Golf'); toggleMenu(); }}>
							<Text style={styles.menuText}>Golf</Text>
						</Pressable>
						<Pressable onPress={() => { navigation.navigate('Events'); toggleMenu(); }}>
							<Text style={styles.menuText}>Events</Text>
						</Pressable>
						<Pressable onPress={() => { navigation.navigate('Contact'); toggleMenu(); }}>
							<Text style={styles.menuText}>Contact</Text>
						</Pressable>
				</Animated.View>
				
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		height: 65,
		backgroundColor: "#15231d",
		elevation: 10,
		zIndex: 1000,
	},
	headerContent: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flex: 1,
	},
	navItemsContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},
	logoContainer: {
		marginLeft: 'auto', // Pushes the logo to the right
	},
	logo: {
		width: isSmallDevice ? 50 : 75,
		height: isSmallDevice ? 50 : 75,
		right: 25
	},
	hamburger: {
		padding: 10, // Add padding to increase touchable area
		zIndex: 1001, // Increase if necessary to bring it to the front
		alignSelf: 'center', // Center vertically
	},
	hamburgerIcon: {
		fontSize: 24,
		color: "#f7f7e0",
		left: 25,
		zIndex: 9999, // Ensure it's above other content
	},
	menuOverlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		width: '100%', // Full width
		height: '100%', // Full height
		backgroundColor: 'rgba(21, 35, 29, 0.85)', // Slightly transparent for visibility
		zIndex: 1000, // Ensure it's above other content but below the close button
		textShadowColor: "rgba(0, 0, 0, 0.75)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
	},
	menuTopper: {
		backgroundColor: "rgba(21, 35, 29, 0.85)", // Slightly transparent for visibility
		width: '100%',
		height: 10000, // Full height
		position: 'absolute',
		padding: 50,
		top: 0,
		left: 0,
		right: 0,
	},
	closeButton: {
		position: 'absolute',
		top: 10,
		right: 20,
		paddingRight: 10,
		zIndex: 1001, // Ensure it's above other content
	},
	menuText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: 22,
		color: "#f7f7e0",
		top: 50,
		paddingLeft: 25,
		paddingBottom: 20,
	},
	navText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: 22,
		color: "#f7f7e0",
		padding: 10,
		marginLeft: 20, // Added spacing between menu items
	}
});

export default NavBarHeader;

