import React, { useState, useEffect, useRef } from "react";
import { View, Text, ScrollView, TouchableOpacity, ImageBackground, Pressable, TextInput, Linking, Touchable } from "react-native";
import { Link, animateScroll as scroll } from "react-scroll";
import { useNavigation } from "@react-navigation/native";
import styles from "../Styles/ContactStyles";
import NavbarHeader from "../Navigation/NavBarHeader";
import ContactForm from "../Widgets/ContactForm";
import InteractiveMap from "../Widgets/InteractiveMap";
import JobListing from "../Widgets/JobListing";
import Footer from "../Navigation/Footer";
import { getFirestore, getDocs, collection } from "firebase/firestore";

const Contact = () => {
	const navigation = useNavigation();
	const scrollViewRef = useRef(null);
	const getDirectionsRef = useRef(null);
	const membershipRef = useRef(null);
	const jobOpeningsRef = useRef(null);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [jobListings, setJobListings] = useState([]);

	useEffect(() => {
		fetchJobListings();
	}, []);

	const handleMembershipSubmit = () => {
		// You can include additional checks or validations here
		console.log("Form submitted:", { name, email });
		const emailRecipient = "club@somersetcountryclub.com";
		const emailSubject = encodeURIComponent(`Membership Inquiry - ${name}`);
		const emailBody = encodeURIComponent(`Name: ${name}\nEmail: ${email}\n\n Hi there! I'm reaching out to request additional information regarding membership! \n\n Thank you, \n ${name}`);
		// Open the mail app
		Linking.openURL(`mailto:${emailRecipient}?subject=${emailSubject}&body=${emailBody}`);
	};

	const handleLinkPress = (pageName) => {
		// You can implement navigation logic here to navigate to the desired page
		console.log(`Navigating to ${pageName}`);
		navigation.navigate(pageName);
	};

	const fetchJobListings = async () => {
		const db = getFirestore();
		const querySnapshot = await getDocs(collection(db, "jobListings"));
		const fetchedJobListings = querySnapshot.docs.map((doc) => {
			const data = doc.data();
			return {
				id: doc.id,
				title: data.title,
				description: data.description,
				// Optionally include a date or other fields as necessary
			};
		});
		setJobListings(fetchedJobListings);
	};

	return (
		<View style={styles.body}>
			{/* Conditionally render NavbarHeader with sticky positioning */}
			<View style={{ position: "sticky", top: 0, zIndex: 1000 }}>
				<NavbarHeader />
			</View>

			<ScrollView ref={scrollViewRef} style={styles.body} scrollEventThrottle={16}>
				{/* Contact  */}
				<View style={styles.section}>
					<ImageBackground source={require("../assets/Example_Background.png")} style={styles.background}>
						<Text style={styles.headerText}>Contact</Text>

						<View style={styles.navBar}>
							{/* Home link */}
							<Link to="getDirections" smooth={true} duration={500} offset={-60}>
								<Text style={styles.navBarText}>Get Directions</Text>
							</Link>

							<Link to="membership" smooth={true} duration={500} offset={-60}>
								<Text style={styles.navBarText}>Membership</Text>
							</Link>

							<Link to="jobOpenings" smooth={true} duration={500} offset={-60}>
								<Text style={styles.navBarText}>Job Openings</Text>
							</Link>
						</View>

						{/* Form container */}
						<View style={styles.contactForm}>
							<ContactForm />
						</View>
					</ImageBackground>
				</View>

				{/* Get Directions */}
				<View id="getDirections" style={styles.section}>
					<ImageBackground source={require("../assets/Example_Background_2.png")} style={styles.background}>
						<Text style={styles.headerText}>Get Directions</Text>
						<InteractiveMap />
					</ImageBackground>
				</View>

				{/* Membership*/}
				<View id="membership" style={styles.section}>
					<ImageBackground source={require("../assets/clubhouse.png")} style={styles.background}>
						<Text style={styles.headerText}>Membership</Text>
						<View style={styles.membershipContainer}>
							<Text style={styles.membershipCardHeader}>Join the Club</Text>
							<Text style={styles.descriptionText}>
								Discover a club that matches your passion for golf and leisure. At Somerset Country Club, membership is about more than just golf; it’s about becoming part of a blossoming community where sport, comfort, and camaraderie come together seamlessly.
								<br /> Ready to dive in? Fill out the information below to receive our full member application and join our club!
							</Text>

							<Text style={styles.label}>Full Name</Text>
							<TextInput value={name} onChangeText={setName} placeholder="Jane Doe" style={styles.input} />
							<Text style={styles.label}>Email</Text>
							<TextInput value={email} onChangeText={setEmail} placeholder="info@example.com" style={styles.input} />

							<TouchableOpacity title="Submit" onPress={handleMembershipSubmit} style={styles.submitButton}>
								<Text style={styles.submitButtonText}>Submit</Text>
							</TouchableOpacity>
						</View>
						{/* Golf Container (Widget) */}
					</ImageBackground>
				</View>
				{/* Upcoming Events */}

				{/* Job Openings */}
				<View id="jobOpenings" style={styles.section}>
					<ImageBackground source={require("../assets/Example_Background_2.png")} style={styles.background}>
						<Text style={styles.headerText}>Job Openings</Text>
						{jobListings.length > 0 ? (
							jobListings.map((listing) => (
								<Pressable key={listing.id} onPress={() => console.log("Job detail")}>
									<View style={styles.innerContainer}>
										<Text style={styles.containerHeaderText}>{listing.title}</Text>
										<Text style={styles.containerDescriptionText}>{listing.description}</Text>
										{/* Optionally display other fields like date */}
									</View>
								</Pressable>
							))
						) : (
							<View style={styles.jobListingContainer}>
								<Text style={styles.containerDescriptionText}>No job openings at the moment. Check back soon!</Text>
							</View>
						)}
					</ImageBackground>
				</View>

				<Footer />
			</ScrollView>
		</View>
	);
};

export default Contact;
