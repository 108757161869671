import React, { useState, useEffect } from "react";
import { View, Text, ActivityIndicator } from "react-native";
import { NavigationContainer, useLinking } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import Home from "../Screens/Home";
import Golf from "../Screens/Golf";
import Events from "../Screens/Events";
import Contact from "../Screens/Contact";
import Admin from "../Screens/Admin";
import Login from "../Screens/Login";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { app } from "../firebase";

const Stack = createStackNavigator();

const linking = {
  prefixes: ["https://somersetcountryclub.com", "somersetcountryclub://", "http://localhost:19006"],
  config: {
    screens: {
      Home: "",
      Golf: "golf",
      Events: "events",
      Contact: "contact",
      Admin: "admin",
    },
  },
};

const Navigation = () => {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialRoute, setInitialRoute] = useState("Home"); // Dynamically set based on auth status

  useEffect(() => {
    const auth = getAuth(app);
    onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        const db = getFirestore(app);
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists() && docSnap.data().isAdmin) {
          setIsAdmin(true);
          setInitialRoute("Admin");
        } else {
          setIsAdmin(false);
          setInitialRoute("Home");
        }
        setUser(user);
      } else {
        setIsAdmin(false);
        setUser(null);
        setInitialRoute("Login");
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "#214332" }}>
        <ActivityIndicator size="large" color="#f7f7e0" />
      </View>
    );
  }

  return (
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
      <Stack.Navigator initialRouteName={initialRoute}>
        <Stack.Screen name="Home" component={Home} options={{ headerShown: false }} />
        <Stack.Screen name="Golf" component={Golf} options={{ headerShown: false }} />
        <Stack.Screen name="Events" component={Events} options={{ headerShown: false }} />
        <Stack.Screen name="Contact" component={Contact} options={{ headerShown: false }} />
        {/* Conditional rendering based on authentication and admin status */}
        {user && isAdmin ? (
          <Stack.Screen name="Admin" component={Admin} options={{ headerShown: false }} />
        ) : (
          <Stack.Screen name="Login" component={Login} options={{ headerShown: false }} />
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Navigation;
