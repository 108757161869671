// firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAoNe9qVnKwRuIfGYRjrS319RU3iq-DrGE",
  authDomain: "somerset-country-club.firebaseapp.com",
  projectId: "somerset-country-club",
  storageBucket: "somerset-country-club.appspot.com",
  messagingSenderId: "1066871665008",
  appId: "1:1066871665008:web:d2b351eafd8cd84bd11fb5",
  measurementId: "G-DQ3HTEXHYH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Optional: Only if you're using Firebase Analytics
const analytics = getAnalytics(app);

export { app, analytics };
