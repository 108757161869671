import React from "react";
import { View, Text, Pressable, Linking } from "react-native";
import { StyleSheet, Dimensions } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

const { width } = Dimensions.get("window");
const isSmallDevice = width < 600;
const isTablet = width < 1400 && width > 600;

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const Leagues = () => {
	// Function to open a URL
	const openURL = (url) => {
		Linking.openURL(url);
	};

	const handlePhonePress = () => {
		// Open the phone app with the provided phone number
		Linking.openURL("tel:(814)445-8782");
	};
	const handlePhonePress2 = () => {
		// Open the phone app with the provided phone number
		Linking.openURL("tel:(814)483-6512");
	};
	const handleProShop = () => {
		// Open the phone app with the provided phone number
		Linking.openURL("tel:(814)445-5179");
	};

	return (
		<View style={styles.golfContainer}>
			<Text style={styles.headerText}>Golf Leagues</Text>

			<ScrollView>
				{/* Ladies Golf Association */}
				<Text style={styles.boldText}>Ladies Golf Association</Text>
				<Text style={styles.descriptionText}>The Ladies Golf Association of Someret Country Club is an 18 hole league that plays on Tuesday mornings. All Members Welcome!</Text>
				<Pressable onPress={handlePhonePress}>
					<Text style={styles.linkText}>Call Tee Mountain</Text>
				</Pressable>

				{/* Men's League */}
				<Text style={styles.boldText}>Men's League</Text>
				<Text style={styles.descriptionText}>Members of the Men’s League meet and play on Tuesday’s of every week, beginning in April. Please call John McGinnis at the Pro Shop for more information.</Text>
				<Pressable onPress={() => openURL("https://somersetmensleague.wordpress.com/")}>
					<Text style={styles.linkText}>Visit Men's League Website</Text>
				</Pressable>

				{/* The Ladies Thursday Night */}
				<Text style={styles.boldText}>The Ladies Thursday Night</Text>
				<Text style={styles.descriptionText}>The Ladies Thursday Night League tees off for nine holes on Thursdays at 5:30 p.m. This league starts in May. Spend the evening on the links enjoying a friendly, lively game with the girls!</Text>
				<Pressable onPress={handlePhonePress2}>
					<Text style={styles.linkText}>Call Tonya Spangler</Text>
				</Pressable>

				{/* Couples’ League */}
				<Text style={styles.boldText}>Couples’ League</Text>
				<Text style={styles.descriptionText}>The Couples’ League plays on Monday nights. This league starts in May. This is a fun night of golf for everyone!</Text>
				<Pressable onPress={handleProShop}>
					<Text style={styles.linkText1}>Call the Pro Shop</Text>
				</Pressable>

				<View style={styles.spacer}></View>
			</ScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	golfContainer: {
		width: isSmallDevice ? "80%" : isTablet ? 800 : 1000,
		height: isSmallDevice ? "70%" : isTablet ? 550 : 650,
		backgroundColor: "#214332",
		paddingVertical: isSmallDevice ? 15 : 30,
		paddingBottom: 25,
		paddingHorizontal: isSmallDevice ? 5 : 30,
		borderRadius: isSmallDevice ? 25 : 50,
		margin: "auto",
	},
	headerText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 35 : 70,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.25)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginTop: isSmallDevice ? 12.5 : 25,
		lineHeight: isSmallDevice ? 15 : 30,
		marginBottom: isSmallDevice ? 25 : 50,
		zIndex: 1,
	},
	boldText: {
		fontWeight: "bold",
		fontFamily: "Orpheus Pro",
		fontSize: isSmallDevice ? 10 : 20,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .75)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
	descriptionText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 10 : 20,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
	linkText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 10 : 15,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "right",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
	linkText1: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 10 : 15,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "right",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
		paddingBottom: 25,
	},
	navBarText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: isSmallDevice ? 25 : 40,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		paddingHorizontal: isSmallDevice ? 10 : 50,
		zIndex: 1,
	},
	spacer: {
		height: isSmallDevice ? 10 : 25,
	},
});

export default Leagues;
