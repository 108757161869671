import React from "react";
import { View, Text, Pressable, Linking } from "react-native";
import { StyleSheet, Dimensions } from "react-native";

const { width } = Dimensions.get("window");
const isSmallDevice = width < 600;
const isTablet = width < 1400 && width > 600;

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const HIOC = () => {
	const handlePdfDownload = async (pdfUrl) => {
		try {
			const response = await fetch(pdfUrl);
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);

			// Create a new anchor element
			const a = document.createElement("a");
			a.href = url;
			a.download = pdfUrl.split("/").pop(); // Set the file name
			document.body.appendChild(a);
			a.click();

			// Clean up
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		} catch (error) {
			console.error("Download error:", error.message);
		}
	};

	return (
		<View style={styles.golfContainer}>
			<Text style={styles.headerText}>Hole in One Club</Text>
			<Text style={styles.descriptionText}>
				The Men’s Golf Committee sponsors a “Hole-in-One Club” for our golf members. The Hole-in-One Club runs from April 1st thru October 31st. There is a $25.00 entry fee. Please click below for a copy of the rules and application.
				{/* Additional information about the club */}
			</Text>
			<Pressable style={styles.button} onPress={() => handlePdfDownload("../assets/HIOC.pdff")}>
				<Text style={styles.buttonText}>Rules & Application</Text>
			</Pressable>
		</View>
	);
};

const styles = StyleSheet.create({
	golfContainer: {
		width: isSmallDevice ? "80%" : 800,
		height: isSmallDevice ? "70%" : 400,
		backgroundColor: "#214332",
		paddingVertical: isSmallDevice ? 15 : 30,

		paddingHorizontal: isSmallDevice ? 5 : 30,
		borderRadius: isSmallDevice ? 25 : 50,
		margin: "auto",
	},
	headerText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 35 : 70,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.25)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginTop: isSmallDevice ? 12.5 : 25,
		lineHeight: isSmallDevice ? 15 : 30,
		marginBottom: isSmallDevice ? 25 : 50,
		zIndex: 1,
	},
	boldText: {
		fontWeight: "bold",
		fontFamily: "Orpheus Pro",
		fontSize: isSmallDevice ? 10 : 20,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 10, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
	descriptionText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 10 : 20,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
	linkText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 10 : 15,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "right",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
	linkText1: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 10 : 15,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "right",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
		paddingBottom: 25,
	},
	navBarText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: isSmallDevice ? 25 : 40,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .75)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		paddingHorizontal: isSmallDevice ? 10 : 50,
		zIndex: 1,
	},
 button: {
		width: isSmallDevice ? 188 : 376,
		height: isSmallDevice ? 30 : 60,
		backgroundColor: "#f7f7e0",
		justifyContent: "center",
		alignItems: "center",
		marginTop: isSmallDevice ? 10 : 40,
		borderRadius: isSmallDevice ? 15 : 30,
		margin: "auto",
	},
	buttonText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "bold",
		fontSize: isSmallDevice ? 12 : 24,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "center",
		color: "#214332",
		margin: "auto",
	},
	spacer: {
		height: isSmallDevice ? 10 : 25,
	},
});

export default HIOC;
