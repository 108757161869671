// NewsletterPopup.js
import React from "react";
import { Modal, View, Text, TextInput, TouchableOpacity, StyleSheet } from "react-native";

const NewsletterPopup = ({ isVisible, onClose }) => {
	// Function to handle newsletter subscription
	const handleSubscribe = () => {
		console.log("Subscribed to newsletter");
		onClose(); // Close the popup after subscribing
	};

	return (
		<Modal animationType="slide" transparent={true} visible={isVisible} onRequestClose={onClose}>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<TouchableOpacity style={styles.closeButton} onPress={onClose}>
						<Text style={styles.closeButtonText}>X</Text>
					</TouchableOpacity>
					<Text style={styles.headerText}>Subscribe to Our Newsletter</Text>
					<TextInput style={styles.input} placeholder="Enter your email" keyboardType="email-address" placeholderTextColor="#f7f7e0" />
					<TouchableOpacity style={styles.button} onPress={handleSubscribe}>
						<Text style={styles.buttonText}>Subscribe</Text>
					</TouchableOpacity>
				</View>
			</View>
		</Modal>
	);
};
const styles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
	},
	modalView: {
		margin: 20,
		backgroundColor: "#214332",
		borderRadius: 25,
		padding: 30,
		alignItems: "center",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
	},
	closeButton: {
		position: "absolute",
		top: 10,
		right: 10,
		backgroundColor: "#f7f7e0",
		borderRadius: 15,
		width: 30,
		height: 30,
		justifyContent: "center",
		alignItems: "center",
	},
	closeButtonText: {
		color: "#214332",
		fontSize: 16,
		fontWeight: "bold",
	},
	input: {
		height: 40,
		width: 250,
		marginVertical: 12,
		borderWidth: 1,
		borderColor: "#f7f7e0",
		padding: 10,
		borderRadius: 15,
		color: "#f7f7e0",
		backgroundColor: "#214332",
	},
	headerText: {
		fontFamily: "Bodoni72Smallcaps",
		fontSize: 24,
		color: "#f7f7e0",
		textAlign: "center",
		marginBottom: 15,
	},
	button: {
		width: 200,
		height: 50,
		backgroundColor: "#f7f7e0",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 15,
		marginTop: 10,
	},
	buttonText: {
		fontFamily: "Bodoni72Smallcaps",
		fontSize: 18,
		color: "#214332",
	},
});

export default NewsletterPopup;
