import React from "react";
import { View, Text, StyleSheet, Dimensions } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

const { width } = Dimensions.get("window");
const isSmallDevice = width < 600;
const isTablet = width < 1500 && width > 600;

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const Pool = () => {
	return (
		<View style={styles.golfContainer}>
			<Text style={styles.headerText}>Pool Rules</Text>

			<ScrollView>
				<Text style={styles.descriptionText}>Golf Members and Social Members are able to use the pool.</Text>

				<Text style={styles.descriptionText}>Guests who join a member are $7 per person. Food & Beverage service available. No lifeguard on duty.</Text>

				<Text style={styles.descriptionText}>The pool is open Memorial Day to Labor Day.</Text>
				<Text style={styles.boldText}>Hours:</Text>
				<Text style={styles.descriptionText}>Monday - Saturday 11am - 8pm, Sunday - 11am - 7pm</Text>

				<Text style={styles.rowLead}>Swimming Pool Rules and Regulations</Text>
				{poolRules.map((rule, index) => (
					<Text key={index} style={styles.descriptionLeft}>
						{index + 1}. {rule}
					</Text>
				))}
			</ScrollView>
		</View>
	);
};

const poolRules = [
	"Children under 16 years of age must be accompanied by a parent, responsible adult, or a responsible babysitter. Babysitter must be 16 years of age or older. This rule will be strictly enforced. If abused, member will lose pool privileges.",
	"No person is permitted to use the pool when the signs reading 'Pool Closed' are in place.",
	"No running, pushing, dunking, diving, rough games, or horseplay is allowed in the pool area.",
	"Rafts and inner tubes may be used in the pool.",
	"Anyone may be called upon by the staff to prove their swimming ability at any time. Children who have not completed lifesaving courses will be required to swim two lengths of the pool in order to swim in the deep end.",
	"Swimming attire is not permitted in the clubhouse, bar area, putting green, or golf course.",
	"The Club is not responsible for lost or stolen property.",
	"All guests will be subject to a fee deemed by the Board of Governors.",
	"No glassware or glass bottles are permitted within the gated area of the pool.",
	"No outside food or drink is permitted at the pool or clubhouse. Food and beverages may be purchased at the clubhouse.",
	"Children under 12 are not permitted on the putting green unless accompanied by an adult.",
	"Children are permitted in the locker rooms but must be accompanied by an adult.",
	"Pool hours will be at the discretion of the Club Manager.",
	"There will be no lifeguards. Signs are posted that there is no lifeguard on duty, swim at your own risk.",
];

const styles = StyleSheet.create({
	golfContainer: {
		width: isSmallDevice ? "80%" : isTablet ? 1000 : 1000,
		height: isSmallDevice ? "70%" : isTablet ? 600 : 600,
		backgroundColor: "#214332",
		paddingVertical: isSmallDevice ? 15 : 30,
		paddingHorizontal: isSmallDevice ? 5 : 30,
		borderRadius: isSmallDevice ? 25 : 50,
		margin: "auto",
		marginTop: 20,
	},
	headerText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 35 : 70,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.25)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginTop: isSmallDevice ? 12.5 : 25,
		lineHeight: isSmallDevice ? 15 : 30,
		marginBottom: isSmallDevice ? 25 : 50,
		zIndex: 1,
	},
	boldText: {
		fontWeight: "bold",
		fontFamily: "Orpheus Pro",
		fontSize: isSmallDevice ? 10 : 20,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
	descriptionText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 10 : 20,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
	descriptionLeft: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 10 : 20,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
	rowLead: {
		fontWeight: "bold",
		fontFamily: "Orpheus Pro",
		fontSize: isSmallDevice ? 10 : 20,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
		paddingTop: 25,
	},
});

export default Pool;
